.main {
  animation-name: opacityAnimation;
  animation-duration: 2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.main img {
  margin: 2rem 0;
}
.main img,
.main .content {
  max-width: 70ch;
}
.main img p,
.main .content p {
  font-size: 16px;
  line-height: 1.5;
  font-family: "Source Sans Pro", sans-serif;
}
.main img p strong,
.main .content p strong {
  font-weight: 600;
}
.main img p a,
.main .content p a {
  color: #0070d8;
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid #0070d8;
}
.main img .cols,
.main .content .cols {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .cols {
    flex-direction: row;
  }
}
@media only screen and (max-width: 600px) {
  .main {
    padding: 1rem;
  }
  .main img {
    max-width: 100%;
  }
  .main .cols {
    flex-direction: column;
  }
  .main .cols p {
    width: 100%;
  }
}
@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=globals.css.map */