$blue: #0070d8;

.main {
  animation-name: opacityAnimation;
  animation-duration: 2s;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  img {
    margin: 2rem 0;
  }

  img,
  .content {
    max-width: 70ch;
    p {
      font-size: 16px;
      line-height: 1.5;
      font-family: "Source Sans Pro", sans-serif;
      strong {
        font-weight: 600;
      }

      a {
        color: $blue;
        font-weight: 600;
        text-decoration: none;
        border-bottom: 1px solid $blue;
      }
    }

    .cols {
      display: flex;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 600px) {
  .cols {
    flex-direction: row;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    padding: 1rem;
    img {
      max-width: 100%;
    }

    .cols {
      flex-direction: column;
      p {
        width: 100%;
      }
    }
  }
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
